document.addEventListener("DOMContentLoaded", () => {
  const sliderContainers = document.querySelectorAll(".slider-container");

  sliderContainers.forEach((sliderContainer) => {
    const imgList = sliderContainer.querySelectorAll(".img-container");
    const pointerContainer = sliderContainer.querySelector(".pointers");
    const pointers = sliderContainer.querySelectorAll(".pointers .point");

    let active = pointers[0];

    if (getComputedStyle(pointerContainer).display !== "none") {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            active.classList.remove("active");
            let activeListItemIndex = [...imgList].findIndex((item) => item === entry.target);
            active = pointers[activeListItemIndex];
            active.classList.add("active");
          }
        });
      }, {threshold: 0.75});

      imgList.forEach((item) => {
        observer.observe(item);
      });
    }
  });
  // const iosList = document.querySelectorAll(".ios .img-container");
  // const pointerContainer = document.querySelector(".ios-pointers");
  // const iosPointers = document.querySelectorAll(".ios-pointers .point");
  // let active = iosPointers[0];
  //
  // if (getComputedStyle(pointerContainer).display !== "none") {
  //   const observer = new IntersectionObserver((entries) => {
  //     console.log('working');
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         active.classList.remove("active");
  //         let activeListItemIndex = [...iosList].findIndex((item) => item === entry.target);
  //         active = iosPointers[activeListItemIndex];
  //         active.classList.add("active");
  //       }
  //     });
  //   }, {threshold: 0.75});
  //
  //   iosList.forEach((item) => {
  //     observer.observe(item);
  //   });
  // }
});
